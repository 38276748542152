import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Image from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Col, Container, Row } from 'react-bootstrap';
import { SiteMetadata } from '../types/site-metadata';
import { ContentfulPerson } from '../types/contentful-types';

type DataProps = {
  site: SiteMetadata;
  contentfulPerson: ContentfulPerson
};

const PersonTemplate: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const person = data.contentfulPerson;
  const siteTitle = data.site.siteMetadata?.title;
  const bio = { __html: person.bio.childMarkdownRemark.html };
  // Ignore first image since it is the profile picture
  const images = person.images.slice(1).map((image) => {
    return (
      <Image
        fluid={image.fluid}
        alt={image.title}
        key={image.title}
      />
    )
  });
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={person.name}
        description={person.bio.childMarkdownRemark.html}
      />
      <Container fluid={true}>
        <Row>
          <Col className="display-4 text-center font-weight-bold text-uppercase">{person.name}</Col>
        </Row>
        <Row xs={1} md={2}>
          <Col>
            <div dangerouslySetInnerHTML={bio} />
          </Col>
          <Col>
            {images}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PersonTemplate;

export const pageQuery = graphql`
  query PersonBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPerson(slug: {eq: $id }) {
      slug
      name
      bio {
        childMarkdownRemark {
          html
        }
      }
      shortBio {
        childMarkdownRemark {
          html
        }
      }
      images {
        description
        title
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`;
